import { Row, Divider, Col } from "antd";

const AplicacaodosProdutos: React.FC = () => {
  return (
    <center
      style={{
        // display: "flex",
        //alignItems: "center",
        // justifyContent: "center",
        backgroundImage: `url(https://jrperfis.com.br//images/fundos/fundo09.jpg)`,
        // backgroundSize: "cover",
        backgroundPosition: "left top",
        backgroundAttachment: "fixed",
        marginTop: "100px",
        borderStyle: "solid",
        backgroundRepeat: "repeat-x",
        border: 0,
      }}
    >
      <div style={{ width: "90%", textAlign: "left" }}>
        <div>
          <Divider></Divider>
          <h1 style={{ marginTop: "10px" }}>
            <span style={{ fontSize: "24pt", color: "#999999" }}>
              <strong>Aplicação dos produtos</strong>
            </span>
          </h1>
        </div>

        <Row gutter={10}>
          <Col span={6}>
            {" "}
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pag.9.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pag.9.jpeg"}
              />
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-8.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-8.jpeg"}
              />
            </Row>
            <Row>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-13.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-13.jpeg"}
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-2.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-2.jpeg"}
              />
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-5.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-5.jpeg"}
              />
            </Row>
            <Row>
              {" "}
              <img
                width={"100%"}
                src={
                  "https://jrperfis.com.br/images/projetos/WhatsApp-Image-2022-05-17-at-16.41.37-6.jpeg"
                }
                alt={
                  "https://jrperfis.com.br/images/projetos/WhatsApp-Image-2022-05-17-at-16.41.37-6.jpeg"
                }
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-3.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-3.jpeg"}
              />
            </Row>
            <Row>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-9.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-9.jpeg"}
              />
            </Row>
          </Col>
          <Col span={6}>
            <Row style={{ marginBottom: "10px" }}>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-4.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-4.jpeg"}
              />
            </Row>
            <Row>
              {" "}
              <img
                width={"100%"}
                src={"https://jrperfis.com.br/images/projetos/pagina-11.jpeg"}
                alt={"https://jrperfis.com.br/images/projetos/pagina-11.jpeg"}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </center>
  );
};
export default AplicacaodosProdutos;
