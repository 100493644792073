import { Button, Divider, Form, Space, Input } from "antd";
import styled from "styled-components";
import logo from "../../../logojr.png";

function Rodape() {
  const onFinish = (values: { email: any }) => {
    console.log("Email enviado:", values.email);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Erro:", errorInfo);
  };
  return (
    <Footer>
      <GridContainer>
        <LogoContainer>
          <Logo src={logo} alt="Logo" />
        </LogoContainer>
        <GridItem style={{ textAlign: "left" }}>
          {" "}
          <h1>
            <Titulo>
              <strong>Endereço</strong>
            </Titulo>
          </h1>
          <p style={{ fontSize: "10", fontFamily: "DroidSans" }}>
            <Subtitulo> Rua Alencar Araripe, 1466</Subtitulo>
            <Subtitulo>
              <strong>
                Sacomã São Paulo - SP
                <br></br>
                CEP: 04253-000
                <br></br>
                <br></br>
                Tel: 11 2361-1740
              </strong>
            </Subtitulo>
          </p>
        </GridItem>
        <GridItem>
          <h1>
            <Titulo>
              <strong>Receba Novidades</strong>
            </Titulo>
          </h1>
          <Form
            name="email-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              style={{ flex: 1 }}
            >
              <Input placeholder="E-mail" />
            </Form.Item>

            <Form.Item
              style={{
                marginLeft: "auto",
                textAlign: "left",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                color="white"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </GridItem>
      </GridContainer>
    </Footer>
  );
}

export default Rodape;

const Titulo = styled.span`
  color: #999999;
  font-size: 14pt;
  font-family: Arial Black;
`;

const Subtitulo = styled.span`
  display: flex;
  align-items: center;
  font-size: 10pt;
  color: #999999;
  font-family: Droid Sans;
`;

const LogoContainer = styled.div``;
const Logo = styled.img`
  max-width: 100%;
  height: 50%;
  padding: 20px;
`;
const Footer = styled.footer`
  color: #333333;
  font-size: 14px;
  height: 290px;
  display: flex;
  margintop: 20px;
  justify-content: center;
  background-color: #e8e8e8;
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 80%;
`;

const GridItem = styled.div`
  padding: 20px;
`;
