import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner02 from "../app/imgs/Modal-Perfil-Sobrepor-Canto-Circular-16x16mm.jpg";
import banner03 from "../app/imgs/Modal-Perfil-Sobrepor-Canto-Angular-16x16mm.jpg";
import banner04 from "../app/imgs/modal-Perfil-de-Sobrepor-17x15mm.jpg";

import banner05 from "../app/imgs/modal-Perfil-de-Sobrepor-No-Frame-30x55mm.jpg";
import banner06 from "../app/imgs/modal-Molas.jpg";
import banner07 from "../app/imgs/Modal-Abracadeiras-e-Emendas.jpg";
import banner08 from "../app/imgs/modal-tampas.jpg";
import banner09 from "../app/imgs/ALTERARAFOTODOPERFIL24X7DAPAGINA3PARAESSA.jpeg";
import banner10 from "../app/imgs/27.jpg";
import banner11 from "../app/imgs/Modal-Perfil-Embutir-24x19mm.jpg";
import banner12 from "../app/imgs/Perfil-de-Embutir-27x28.jpg";
import banner13 from "../app/imgs/Modal-Perfil-Embutir-50x35mm.jpg";

import styled from "styled-components";

const NossosProdutos: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, // troca de slide a cada 3 segundos
    direction: "rtl", // direção da animação
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
  };

  const images = [
    {
      image: banner02,
      name: "Perfil Sobrepor Canto Circular 16x16mm",
      description: "Perfil Sobrepor Canto Circular 16x16mm",
    },
    {
      image: banner03,
      name: "Perfil Sobrepor Canto Angular 16x16mm",
      description: "Perfil Sobrepor Canto Angular 16x16mm",
    },
    {
      image: banner04,
      name: "Perfil de Sobrepor 17x15mm",
      description: "Perfil de Sobrepor 17x15mm",
    },
    {
      image: banner05,
      name: "Perfil de Sobrepor No-Frame 30x55mm",
      description: "Perfil de Sobrepor No-Frame 30x55mm",
    },
    {
      image: banner06,
      name: "Molas",
      description: "Molas",
    },
    {
      image: banner07,
      name: "Abraçadeiras e Emendas",
      description: "Abraçadeiras e Emendas",
    },
    {
      image: banner08,
      name: "Tampas",
      description: "Tampas",
    },
    {
      image: banner09,
      name: "Perfil de Embutir 27x4",
      description: "ALTERARAFOTODOPERFIL24X7DAPAGINA3PARAESSA.jpeg",
    },

    {
      image: banner10,
      name: "Perfil de Embutir 24x14",
      description: "27.jpg",
    },

    {
      image: banner11,
      name: "Perfil de Embutir 24x19",
      description: "Modal-Perfil-Embutir-24x19mm.jpg",
    },

    {
      image: banner12,
      name: "Perfil de Embutir 36x28",
      description: "Perfil-de-Embutir-27x28.jpg",
    },

    {
      image: banner13,
      name: "Perfil de Embutir 50x35",
      description: "Modal-Perfil-Embutir-50x35mm.jpg",
    },
  ];
  return (
    <SliderWrapper {...settings} rtl={false}>
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            height: "50px",
            width: "80%",
            backgroundColor: "#8f8f8f",
          }}
        >
          <Image
            src={image.image}
            alt={`slide-${index}`}
            style={{
              width: "80%",
            }}
          />
          <div
            style={{
              height: "35px",
              width: "80%",
              backgroundColor: "#8f8f8f",
              borderRadius: "0 0 20px 20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <a>{image.name}</a>
          </div>
        </div>
      ))}
    </SliderWrapper>
  );
};

const SliderWrapper = styled(Slider)`
  //display: flex;
  //gap: 10px;

  .slick-slide {
    transition: transform 0.3s ease;
  }

  .slick-slide:not(.slick-active) {
    transform: translateX(100%);
  }

  .slick-slide.slick-active {
    transform: translateX(0);
  }

  .slick-slide:last-child {
    position: absolute;
    top: 0;
    left: 0;
    transition-delay: 0s;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export default NossosProdutos;
