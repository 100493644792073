import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Home from "../view/Home.view";

export default function App() {
  const history = useHistory();
  const location = useLocation();

  return (
    <Switch>
      <Route path="/" exact component={Home} />
    </Switch>
  );
}
