import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner02 from "../app/imgs/banner02.jpg";
import banner03 from "../app/imgs/banner03.jpg";
import banner04 from "../app/imgs/banner04.jpg";
import styled from "styled-components";

function SamplePrevArrow(props: { onClick?: any }) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev" onClick={onClick}>
      <i className="fas fa-chevron-left"></i>
    </div>
  );
}

function SampleNextArrow(props: { onClick?: any }) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <i className="fas fa-chevron-right"></i>
    </div>
  );
}

const SimpleSlider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // troca de slide a cada 3 segundos
    direction: "rtl", // direção da animação
    // prevArrow: <SamplePrevArrow />,
    // nextArrow: <SampleNextArrow />,
    beforeChange: (oldIndex: number, newIndex: number) =>
      setCurrentSlide(newIndex),
  };
  const images = [banner02, banner03, banner04];
  return (
    <SliderWrapper {...settings} rtl={true}>
      {images.map((image, index) => (
        <div key={index}>
          <Image src={image} alt={`slide-${index}`} />
        </div>
      ))}
    </SliderWrapper>
  );
};

const SliderWrapper = styled(Slider)`
  position: relative;
  width: 100%;
  font-size: 0px;
  line-height: 0;
  max-height: 100%;
  overflow: hidden;
  .slick-slide {
    transition: transform 0.3s ease;
  }

  .slick-slide:not(.slick-active) {
    transform: translateX(100%);
  }

  .slick-slide.slick-active {
    transform: translateX(0);
  }

  .slick-slide:last-child {
    position: absolute;
    top: 0;
    left: 0;
    transition-delay: 0s;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export default SimpleSlider;
