import { Navbar, Nav, Container } from "react-bootstrap";
import styled from "styled-components";
import logo from "../../../Baixa-resolucao.-sem-brilhopng.png";

const StyledNavbar = styled(Navbar)`
  border-bottom: 1px solid #ddd;
  height: 130px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* cor branca com 50% de opacidade */
`;

const Logo = styled.img`
  height: 90px;
  margin-right: 20px;
`;

const StyledNav = styled(Nav)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

const StyledNavLink = styled(Nav.Link)`
  text-decoration: none;
  font-size: 20px;
  color: #000000; /* cor preta para o texto */
  &:hover {
    color: yellow;
  }
`;

const NavBar = () => {
  return (
    <>
      <StyledNavbar expand="md">
        <Container>
          <Navbar.Collapse id="navbar-nav">
            <StyledNav className="ml-auto">
              <Navbar.Brand href="/">
                <Logo src={logo} alt="Logo" />
              </Navbar.Brand>
              <StyledNavLink href="/">Home</StyledNavLink>
              <StyledNavLink href="/sobre-nos">Sobre nós</StyledNavLink>
              <StyledNavLink href="/fabricacao">Fabricação</StyledNavLink>
              <StyledNavLink href="/produtos">Produtos</StyledNavLink>
              <StyledNavLink href="/distribuidores">
                Distribuidores
              </StyledNavLink>
              <StyledNavLink href="/contato">Contato</StyledNavLink>
            </StyledNav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </>
  );
};

export default NavBar;
