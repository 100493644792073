import { createGlobalStyle } from "styled-components";
import CustomCarousel from "../../CustomCarousel";
import SimpleSlider from "../../SimpleSlider";
import NavBar from "./Navbar";
import Rodape from "./Rodape";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

const GlobalStyle = createGlobalStyle`


  body {
    font-family: 'Droid Sans', sans-serif;
    margin:0;
    pading:0;
    box-sizing: border-box;
    width:100%;
  }

`;

function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <>
      <GlobalStyle></GlobalStyle>

      <NavBar></NavBar>
      <SimpleSlider></SimpleSlider>

      <div>{props.children}</div>
      <Rodape></Rodape>
    </>
  );
}

export default DefaultLayout;
