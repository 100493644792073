import DefaultLayout from "../app/layouts/Default/DefaultLayout";
import Bolha1 from "../app/imgs/Bolha1.png";
import entreEmContato from "../app/imgs/entre-em-contato-jrperfis.png";
import baixeCatalogo from "../app/imgs/baixe-catalogo-jrperfis.png";
import fundo02 from "../app/imgs/fundo02.jpg";
import styled from "styled-components";
import { useEffect, useState } from "react";
import NossosProdutos from "../app/NossosProdutos";
import AplicacaodosProdutos from "../app/Aplicacaodosprodutos";

export default function Home() {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimated(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  `;

  const ImagemL = styled.img`
    ${isAnimated ? "" : ".animated"} {
      transform: translateX(-100%);
      animation: slide-in 1s forwards;
    }

    @keyframes slide-in {
      100% {
        transform: translateX(0);
      }
    }
  `;

  const LeftDiv = styled.p`
    width: 50vw;
    height: 100vh;
    position: absolute;
    left: 10;
    transform: translateX(-100%);
    animation: slide-in 1s forwards;

    ${isAnimated ? "" : ".animated"} {
      transform: translateX(-100%);
      animation: slide-in 1s forwards;
    }

    @keyframes slide-in {
      100% {
        transform: translateX(0);
      }
    }
  `;

  const H5mod = styled.h5`
    line-height: 2;
    font-size: 14px;
    font-weight: 500;
  `;

  const H1 = styled.h1`
    font-family: Arial Black;
    font-size: 2em;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  `;

  const ImagemR = styled.img`
    ${isAnimated ? "" : ".animated"} {
      transform: translateX(100%);
      animation: slide-in 1s forwards;
    }

    @keyframes slide-in {
      100% {
        transform: translateX(0);
      }
    }
  `;
  const RightDiv = styled.div`
    width: 40vw;
    height: 100vh;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    animation: slide-in 1s forwards;

    ${isAnimated ? "" : ".animated"} {
      transform: translateX(100%);
      animation: slide-in 1s forwards;
    }

    @keyframes slide-in {
      100% {
        transform: translateX(0);
      }
    }
  `;
  return (
    <DefaultLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "80px",
        }}
      >
        <div
          style={{
            width: "80%",
          }}
        >
          <Container
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "baseline",
              justifyContent: "center",
              gap: 32,
              marginTop: 30,
              height: "100vh",
              color: "#333333",
            }}
          >
            <div>
              <H1>Nosso trabalho está nos detalhes</H1>
              <H5mod>
                <strong>
                  JRPERFIS é uma marca nacional de perfis de iluminação
                </strong>
                . Nascida em 2021, o propósito da JRPERFIS é{" "}
                <strong>
                  fornecer uma grande variedade de perfis de iluminação
                </strong>{" "}
                para o mercado nacional e atender os mais variados mercados com
                soluções práticas e eficazes.
              </H5mod>
              <H5mod>
                Nosso processo de fabricação contém alumínio (que compõe 68% do
                produto final), conseguimos entregar nossos produtos com o{" "}
                <strong>
                  melhor custo-benefício e no menor tempo possível
                </strong>
                .
              </H5mod>
              <H5mod>
                <strong>
                  Perfis rígidos, leves e resistentes são criados com tecnologia
                  desenvolvida na Alemanha
                </strong>
                , juntamente com nosso próprio processo de extrusão. As marcas
                europeias mais respeitadas utilizam estas configurações nos seus
                produtos.
              </H5mod>
            </div>
            <RightDiv>
              <img
                src={Bolha1}
                alt="Bolha1"
                style={{
                  width: "100%",
                }}
              />
            </RightDiv>
          </Container>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${fundo02})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          style={{
            width: "80%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              gap: 32,
            }}
          >
            <ImagemL
              src={entreEmContato}
              alt="entreEmContato"
              style={{
                width: "50%",
              }}
            />

            <ImagemR
              src={baixeCatalogo}
              alt="baixeCatalogo"
              style={{
                width: "50%",
              }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 60,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "initial",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "initial",
              justifyContent: "initial",
              width: "80%",
            }}
          >
            <h1>
              <span style={{ fontSize: "24pt", color: "#999999" }}>
                <strong>Nossos Produtos</strong>
              </span>
            </h1>
          </div>
          <div style={{ marginTop: "50px", width: "100%" }}>
            <NossosProdutos></NossosProdutos>
            <AplicacaodosProdutos></AplicacaodosProdutos>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
